<template>
  <div>
    <app-breadcrumb
      title="Detail User"
      hide-breadcrumbs
    />
    <v-row>
      <v-col
        cols="12"
        md="4"
        class="d-flex flex-column"
      >
        <v-card>
          <template v-if="userDetail && userDetail.subscription && userDetail.user_status">
            <v-card-text
              class="d-flex flex-column rounded-t-0"
            >
              <div class="d-flex align-center mb-4">
                <v-avatar
                  size="40px"
                  color="primary"
                  class="v-avatar-light-bg primary--text"
                >
                  <v-img
                    v-if="userDetail.photo !== null"
                    :src="userDetail.photo"
                  />
                  <span v-else>
                    {{ avatarText(`${userDetail.first_name} ${userDetail.last_name}`) }}
                  </span>
                </v-avatar>
                <span class="text-subtitle-2 primary--text ms-2">{{ userDetail.name }}</span>
              </div>
              <span class="text-subtitle-2 text--disabled mt-4">Email</span>
              <span class="text-subtitle-2 font-medium">{{ userDetail.email }}</span>
              <span class="text-subtitle-2 text--disabled mt-4">Nomor Telepon</span>
              <span class="text-subtitle-2 font-medium">{{ userDetail.phone ? userDetail.phone : '-' }}</span>
              <span class="text-subtitle-2 text--disabled mt-4">Akun Dibuat pada</span>
              <span class="text-subtitle-2 font-medium">{{ formatDate(userDetail.created_at) }}</span>
              <span class="text-subtitle-2 text--disabled mt-4">Tipe Subskripsi</span>
              <div class="mt-2">
                <v-tooltip
                  open-on-hover
                  top
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip
                      label
                      class="text-subtitle-2 font-medium"
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ userDetail.subscription.name }}
                    </v-chip>
                  </template>
                  <div>{{ userDetail.subscription_ends_at ? `Berlaku Hingga ${formatDate(userDetail.subscription_ends_at)}` : 'Berlaku Selamanya' }}</div>
                  <div
                    v-if="userDetail.subscription.id != 1"
                    class="mt-2"
                  >
                    Perpanjang Otomatis: {{ userDetail.is_auto_subscribe ? 'Aktif' : 'Nonaktif' }}
                  </div>
                </v-tooltip>
              </div>
              <span class="text-subtitle-2 text--disabled mt-4">Status User</span>
              <div>
                <v-chip
                  :color="statusColor[userDetail.user_status.id - 1]"
                  label
                  class="text-subtitle-2 font-medium mt-2"
                >
                  {{ userDetail.user_status.name }}
                </v-chip>
              </div>
            </v-card-text>
            <v-divider />
            <v-card-actions>
              <v-spacer />
              <v-btn
                v-if="userDetail.user_status.id > 1"
                :color="userDetail.user_status.name === 'Verified' ? 'error' : 'success'"
                class="primary"
                :loading="loadingChangeBanUser"
                :disabled="loadingChangeBanUser"
                @click="confirmToggleBanUser(userDetail)"
              >
                {{ userDetail.user_status.name === 'Verified' ? 'Nonaktifkan' : 'Aktifkan' }}
              </v-btn>
            </v-card-actions>
          </template>

          <v-skeleton-loader
            v-else
            class="d-flex flex-column rounded-t-0"
            type="list-item-avatar, article, article"
            height="440px"
          />
        </v-card>
        <v-card class="mt-4">
          <v-card-title>Daftar Workspace User</v-card-title>
          <v-data-table
            :headers="headerWorkspace"
            :items="userWorkspaceList"
            item-key="id"
            disable-sort
            :server-items-length="userWorkspaceCount"
            mobile-breakpoint="0"
            :loading="loadingUserWorkspaceList"
            disable-pagination
            disable-filtering
            hide-default-footer
            fixed-header
            height="325px"
          >
            <template v-slot:[`item.name`]="{ item }">
              <div class="d-flex align-center">
                <v-avatar
                  size="40px"
                  color="primary"
                  class="v-avatar-light-bg primary--text"
                >
                  <span>
                    {{ avatarText(item.name) }}
                  </span>
                </v-avatar>
                <span class="text-subtitle-2 ms-2">{{ item.name }}</span>
              </div>
            </template>
            <template v-slot:[`item.created_at`]="{ item }">
              {{ formatDate(item.created_at) }}
            </template>
            <template v-slot:[`item.member_count`]="{ item }">
              {{ item.member_count }}
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="8"
      >
        <div class="d-flex flex-column">
          <v-card>
            <v-card-title>Daftar Workflow</v-card-title>
            <v-data-table
              :headers="headerWorkflow"
              :items="userWorkflowList"
              :items-per-page="10"
              item-key="id"
              disable-sort
              :server-items-length="userWorkflowCount"
              :footer-props="{
                'items-per-page-options': [10, 25, 50],
                'items-per-page-text': 'Jumlah data',
              }"
              mobile-breakpoint="0"
              :loading="loadingUserWorkflowList"
              fixed-header
              :options.sync="workflowPagination"
              height="325px"
            >
              <template v-slot:[`item.name`]="{ item }">
                <router-link
                  :to="{
                    name: 'admin-workflow-detail',
                    params: {
                      id: item.id,
                    },
                  }"
                >
                  <span>{{ item.name }}</span>
                </router-link>
              </template>
              <template v-slot:[`item.created_at`]="{ item }">
                {{ formatDate(item.created_at) }}
              </template>
              <template v-slot:[`item.workspace`]="{ item }">
                <div class="d-flex align-center">
                  <v-avatar
                    size="40px"
                    color="primary"
                    class="v-avatar-light-bg primary--text"
                  >
                    <span>
                      {{ avatarText(item.workspace.name) }}
                    </span>
                  </v-avatar>
                  <span class="text-subtitle-2 ms-2">{{ item.workspace.name }}</span>
                </div>
              </template>
              <template v-slot:[`item.status`]="{ item }">
                <v-chip
                  label
                  :color="item.is_disabled ? 'secondary' : 'primary'"
                >
                  {{ item.is_disabled ? 'Nonaktif' : 'Aktif' }}
                </v-chip>
              </template>
            </v-data-table>
          </v-card>
          <v-card class="mt-4">
            <v-card-title>Riwayat Transaksi</v-card-title>
            <v-data-table
              :headers="headerTransaction"
              :items="userTransactionList"
              :items-per-page="10"
              item-key="id"
              disable-sort
              :server-items-length="userTransactionCount"
              :footer-props="{
                'items-per-page-options': [10, 25, 50],
                'items-per-page-text': 'Jumlah data',
              }"
              mobile-breakpoint="0"
              :loading="loadingUserTransactionList"
              fixed-header
              :options.sync="transactionPagination"
              height="325px"
            >
              <template v-slot:[`item.midtrans_order_id`]="props">
                <div class="d-flex py-2">
                  {{ props.item.midtrans_order_id }}
                </div>
              </template>
              <template v-slot:[`item.status`]="props">
                <div class="d-flex py-2">
                  <v-chip
                    :color="resolveStatusColor(props.item.status.name)"
                    outlined
                    class="text-capitalize"
                  >
                    {{ props.item.status.name }}
                  </v-chip>
                </div>
              </template>
              <template v-slot:[`item.payment_type`]="props">
                <div class="d-flex py-2">
                  <v-chip
                    v-if="props.item.payment_type"
                    :color="props.item.payment_type.color"
                    outlined
                    class="text-uppercase"
                  >
                    {{ props.item.payment_type ? props.item.payment_type.name : '' }}
                  </v-chip>
                </div>
              </template>
              <template v-slot:[`item.created_at`]="props">
                <div class="d-flex py-2">
                  {{ formatDate(props.item.created_at, { year: 'numeric', day: 'numeric', month: 'short', hour: 'numeric', minute: 'numeric' }) }}
                </div>
              </template>
              <template v-slot:[`item.gross_amount`]="props">
                <div class="d-flex py-2">
                  {{ formatCurrency(props.item.gross_amount) }}
                </div>
              </template>
            </v-data-table>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { onMounted, ref, watch } from '@vue/composition-api'
import useUser from '@/composables/useUser'
import AppBreadcrumb from '@/components/misc/AppBreadcrumb.vue'
import { avatarText, formatDate, formatCurrency } from '@core/utils/filter'
import Vue from 'vue'

export default {
  components: {
    AppBreadcrumb,
  },
  props: {
    id: {
      type: [String, Number],
      default: null,
      required: true,
    },
  },
  setup(props) {
    const {
      fetchUserDetail, loadingDetailUser, userDetail,
      fetchUserWorkspaceList, userWorkspaceCount, loadingUserWorkspaceList, userWorkspaceList,
      fetchUserWorkflowList, userWorkflowCount, loadingUserWorkflowList, userWorkflowList,
      fetchUserTransactionList, userTransactionCount, loadingUserTransactionList, userTransactionList,
      changeBanUser, loadingChangeBanUser,
    } = useUser()

    const workflowPagination = ref({
      page: 1,
      itemsPerPage: 10,
    })

    const transactionPagination = ref({
      page: 1,
      itemsPerPage: 10,
    })

    const fetchWorkflow = () => {
      fetchUserWorkflowList(
        {
          limit: workflowPagination.value.itemsPerPage ? workflowPagination.value.itemsPerPage : 10,
          offset: workflowPagination.value.page && (workflowPagination.value.page - 1) * workflowPagination.value.itemsPerPage,
        },
        +props.id,
      )
    }

    const fetchTransaction = () => {
      fetchUserTransactionList(
        {
          limit: transactionPagination.value.itemsPerPage ? transactionPagination.value.itemsPerPage : 10,
          offset: transactionPagination.value.page && (transactionPagination.value.page - 1) * transactionPagination.value.itemsPerPage,
        },
        {
          user_id: +props.id,
        },
      )
    }

    const confirmToggleBanUser = user => {
      Vue.$dialog({
        title: user.user_status.id === 3 ? 'Unban User' : 'Ban User',
        body: user.user_status.id === 3 ? 'Apakah anda yakin ingin unban user ini?' : 'Apakah anda yakin ingin ban user ini?',
      }).then(result => {
        if (result) {
          const newStatusId = user.user_status.id === 3 ? 2 : 3
          changeBanUser(user.id, newStatusId).then(() => {
            fetchUserDetail(+props.id)
          })
        }
      })
    }

    onMounted(() => {
      fetchUserDetail(+props.id)
      fetchUserWorkspaceList(+props.id)
      fetchWorkflow()
      fetchTransaction()
    })

    const statusColor = ref([
      'warning',
      'success',
      'error',
    ])

    const resolveStatusColor = status => {
      if (status === 'pending') return 'secondary'
      if (status === 'success') return 'success'
      if (status === 'failure') return 'error'
      if (status === 'challenge') return 'warning'

      return ''
    }

    const headerWorkspace = ref([
      {
        text: 'Nama Workspace',
        value: 'name',
      },
      {
        text: 'Tanggal Dibuat',
        value: 'created_at',
      },
      {
        text: 'Jumlah Member',
        value: 'member_count',
      },
    ])

    const headerWorkflow = ref([
      {
        text: 'Nama Workflow',
        value: 'name',
      },
      {
        text: 'Tanggal Dibuat',
        value: 'created_at',
      },
      {
        text: 'Workspace',
        value: 'workspace',
      },
      {
        text: 'Status',
        value: 'status',
      },
    ])

    const headerTransaction = ref([
      {
        text: 'ID',
        value: 'midtrans_order_id',
      },
      {
        text: 'Status',
        value: 'status',
      },
      {
        text: 'Metode Pembayaran',
        value: 'payment_type',
      },
      {
        text: 'Tanggal transaksi',
        value: 'created_at',
      },
      {
        text: 'Jumlah',
        value: 'gross_amount',
      },
      // {
      //   text: 'Aksi',
      //   value: 'action',
      // },
    ])

    watch(workflowPagination, () => {
      fetchWorkflow()
    })

    watch(transactionPagination, () => {
      fetchTransaction()
    })

    return {
      loadingDetailUser,
      userDetail,
      avatarText,
      formatDate,
      statusColor,

      headerWorkspace,

      userWorkspaceCount,
      userWorkspaceList,
      loadingUserWorkspaceList,

      userWorkflowCount,
      userWorkflowList,
      loadingUserWorkflowList,

      headerWorkflow,
      workflowPagination,

      userTransactionCount,
      userTransactionList,
      loadingUserTransactionList,

      headerTransaction,
      transactionPagination,

      formatCurrency,
      resolveStatusColor,
      confirmToggleBanUser,
      loadingChangeBanUser,
    }
  },
}
</script>
