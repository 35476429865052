import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('app-breadcrumb',{attrs:{"title":"Detail User","hide-breadcrumbs":""}}),_c(VRow,[_c(VCol,{staticClass:"d-flex flex-column",attrs:{"cols":"12","md":"4"}},[_c(VCard,[(_vm.userDetail && _vm.userDetail.subscription && _vm.userDetail.user_status)?[_c(VCardText,{staticClass:"d-flex flex-column rounded-t-0"},[_c('div',{staticClass:"d-flex align-center mb-4"},[_c(VAvatar,{staticClass:"v-avatar-light-bg primary--text",attrs:{"size":"40px","color":"primary"}},[(_vm.userDetail.photo !== null)?_c(VImg,{attrs:{"src":_vm.userDetail.photo}}):_c('span',[_vm._v(" "+_vm._s(_vm.avatarText(((_vm.userDetail.first_name) + " " + (_vm.userDetail.last_name))))+" ")])],1),_c('span',{staticClass:"text-subtitle-2 primary--text ms-2"},[_vm._v(_vm._s(_vm.userDetail.name))])],1),_c('span',{staticClass:"text-subtitle-2 text--disabled mt-4"},[_vm._v("Email")]),_c('span',{staticClass:"text-subtitle-2 font-medium"},[_vm._v(_vm._s(_vm.userDetail.email))]),_c('span',{staticClass:"text-subtitle-2 text--disabled mt-4"},[_vm._v("Nomor Telepon")]),_c('span',{staticClass:"text-subtitle-2 font-medium"},[_vm._v(_vm._s(_vm.userDetail.phone ? _vm.userDetail.phone : '-'))]),_c('span',{staticClass:"text-subtitle-2 text--disabled mt-4"},[_vm._v("Akun Dibuat pada")]),_c('span',{staticClass:"text-subtitle-2 font-medium"},[_vm._v(_vm._s(_vm.formatDate(_vm.userDetail.created_at)))]),_c('span',{staticClass:"text-subtitle-2 text--disabled mt-4"},[_vm._v("Tipe Subskripsi")]),_c('div',{staticClass:"mt-2"},[_c(VTooltip,{attrs:{"open-on-hover":"","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VChip,_vm._g(_vm._b({staticClass:"text-subtitle-2 font-medium",attrs:{"label":""}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(_vm.userDetail.subscription.name)+" ")])]}}],null,false,3583547710)},[_c('div',[_vm._v(_vm._s(_vm.userDetail.subscription_ends_at ? ("Berlaku Hingga " + (_vm.formatDate(_vm.userDetail.subscription_ends_at))) : 'Berlaku Selamanya'))]),(_vm.userDetail.subscription.id != 1)?_c('div',{staticClass:"mt-2"},[_vm._v(" Perpanjang Otomatis: "+_vm._s(_vm.userDetail.is_auto_subscribe ? 'Aktif' : 'Nonaktif')+" ")]):_vm._e()])],1),_c('span',{staticClass:"text-subtitle-2 text--disabled mt-4"},[_vm._v("Status User")]),_c('div',[_c(VChip,{staticClass:"text-subtitle-2 font-medium mt-2",attrs:{"color":_vm.statusColor[_vm.userDetail.user_status.id - 1],"label":""}},[_vm._v(" "+_vm._s(_vm.userDetail.user_status.name)+" ")])],1)]),_c(VDivider),_c(VCardActions,[_c(VSpacer),(_vm.userDetail.user_status.id > 1)?_c(VBtn,{staticClass:"primary",attrs:{"color":_vm.userDetail.user_status.name === 'Verified' ? 'error' : 'success',"loading":_vm.loadingChangeBanUser,"disabled":_vm.loadingChangeBanUser},on:{"click":function($event){return _vm.confirmToggleBanUser(_vm.userDetail)}}},[_vm._v(" "+_vm._s(_vm.userDetail.user_status.name === 'Verified' ? 'Nonaktifkan' : 'Aktifkan')+" ")]):_vm._e()],1)]:_c(VSkeletonLoader,{staticClass:"d-flex flex-column rounded-t-0",attrs:{"type":"list-item-avatar, article, article","height":"440px"}})],2),_c(VCard,{staticClass:"mt-4"},[_c(VCardTitle,[_vm._v("Daftar Workspace User")]),_c(VDataTable,{attrs:{"headers":_vm.headerWorkspace,"items":_vm.userWorkspaceList,"item-key":"id","disable-sort":"","server-items-length":_vm.userWorkspaceCount,"mobile-breakpoint":"0","loading":_vm.loadingUserWorkspaceList,"disable-pagination":"","disable-filtering":"","hide-default-footer":"","fixed-header":"","height":"325px"},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c(VAvatar,{staticClass:"v-avatar-light-bg primary--text",attrs:{"size":"40px","color":"primary"}},[_c('span',[_vm._v(" "+_vm._s(_vm.avatarText(item.name))+" ")])]),_c('span',{staticClass:"text-subtitle-2 ms-2"},[_vm._v(_vm._s(item.name))])],1)]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.created_at))+" ")]}},{key:"item.member_count",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.member_count)+" ")]}}],null,true)})],1)],1),_c(VCol,{attrs:{"cols":"12","md":"8"}},[_c('div',{staticClass:"d-flex flex-column"},[_c(VCard,[_c(VCardTitle,[_vm._v("Daftar Workflow")]),_c(VDataTable,{attrs:{"headers":_vm.headerWorkflow,"items":_vm.userWorkflowList,"items-per-page":10,"item-key":"id","disable-sort":"","server-items-length":_vm.userWorkflowCount,"footer-props":{
              'items-per-page-options': [10, 25, 50],
              'items-per-page-text': 'Jumlah data',
            },"mobile-breakpoint":"0","loading":_vm.loadingUserWorkflowList,"fixed-header":"","options":_vm.workflowPagination,"height":"325px"},on:{"update:options":function($event){_vm.workflowPagination=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
            var item = ref.item;
return [_c('router-link',{attrs:{"to":{
                  name: 'admin-workflow-detail',
                  params: {
                    id: item.id,
                  },
                }}},[_c('span',[_vm._v(_vm._s(item.name))])])]}},{key:"item.created_at",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.created_at))+" ")]}},{key:"item.workspace",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c(VAvatar,{staticClass:"v-avatar-light-bg primary--text",attrs:{"size":"40px","color":"primary"}},[_c('span',[_vm._v(" "+_vm._s(_vm.avatarText(item.workspace.name))+" ")])]),_c('span',{staticClass:"text-subtitle-2 ms-2"},[_vm._v(_vm._s(item.workspace.name))])],1)]}},{key:"item.status",fn:function(ref){
                var item = ref.item;
return [_c(VChip,{attrs:{"label":"","color":item.is_disabled ? 'secondary' : 'primary'}},[_vm._v(" "+_vm._s(item.is_disabled ? 'Nonaktif' : 'Aktif')+" ")])]}}],null,true)})],1),_c(VCard,{staticClass:"mt-4"},[_c(VCardTitle,[_vm._v("Riwayat Transaksi")]),_c(VDataTable,{attrs:{"headers":_vm.headerTransaction,"items":_vm.userTransactionList,"items-per-page":10,"item-key":"id","disable-sort":"","server-items-length":_vm.userTransactionCount,"footer-props":{
              'items-per-page-options': [10, 25, 50],
              'items-per-page-text': 'Jumlah data',
            },"mobile-breakpoint":"0","loading":_vm.loadingUserTransactionList,"fixed-header":"","options":_vm.transactionPagination,"height":"325px"},on:{"update:options":function($event){_vm.transactionPagination=$event}},scopedSlots:_vm._u([{key:"item.midtrans_order_id",fn:function(props){return [_c('div',{staticClass:"d-flex py-2"},[_vm._v(" "+_vm._s(props.item.midtrans_order_id)+" ")])]}},{key:"item.status",fn:function(props){return [_c('div',{staticClass:"d-flex py-2"},[_c(VChip,{staticClass:"text-capitalize",attrs:{"color":_vm.resolveStatusColor(props.item.status.name),"outlined":""}},[_vm._v(" "+_vm._s(props.item.status.name)+" ")])],1)]}},{key:"item.payment_type",fn:function(props){return [_c('div',{staticClass:"d-flex py-2"},[(props.item.payment_type)?_c(VChip,{staticClass:"text-uppercase",attrs:{"color":props.item.payment_type.color,"outlined":""}},[_vm._v(" "+_vm._s(props.item.payment_type ? props.item.payment_type.name : '')+" ")]):_vm._e()],1)]}},{key:"item.created_at",fn:function(props){return [_c('div',{staticClass:"d-flex py-2"},[_vm._v(" "+_vm._s(_vm.formatDate(props.item.created_at, { year: 'numeric', day: 'numeric', month: 'short', hour: 'numeric', minute: 'numeric' }))+" ")])]}},{key:"item.gross_amount",fn:function(props){return [_c('div',{staticClass:"d-flex py-2"},[_vm._v(" "+_vm._s(_vm.formatCurrency(props.item.gross_amount))+" ")])]}}],null,true)})],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }